import * as React from "react"
import { graphql } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import Carousel from "react-bootstrap/Carousel"



const IndexPage = ({ data }) => (
  <Layout>
    <Seo title="Home" />
    <div className="text-center">
      <Carousel>
        {data.slideShow.edges.map(({ node }) => (
          <Carousel.Item key={ node.id } >
            <GatsbyImage image= { node.childImageSharp.gatsbyImageData } alt={ node.base.split('-').join(' ').split('.') } />
          </Carousel.Item>
        ))}
      </Carousel>
      {/* About Us Left */}
      <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
              <div>
                <div className="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg">
                  <StaticImage 
                  src="../images/logo/logo-big.png" 
                  alt="Quality Cuts Lawncare Logo" 
                  layout="constrained"
                  placeholder="blurred"
                  />
                </div>
              </div>

              <div className="md:pt-8">
                <p className="text-green-500 font-bold text-center md:text-left">
                  About Us
                </p>

                <h1 className="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">
                  Get to know a little more.
                </h1>

                <p className="text-gray-500 sm:text-lg mb-6 md:mb-8">
                  Quality Cuts is independently owned and operated offering the
                  best lawn-care to both commercial and residential properties.
                  Quality Cuts is registered, insured and certainly qualified to
                  fulfill all of your property maintenance needs. Call, email,
                  or submit a request to set up a fast, free estimate.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Lawn Mowing Right */}
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
              <div className="md:pt-8">
                <p className="text-green-500 font-bold text-center md:text-left">
                  Lawn Mowing
                </p>

                <h1 className="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">
                  Take back your weekends.
                </h1>

                <p className="text-gray-500 sm:text-lg mb-6 md:mb-8">
                  When you let Quality Cuts take care of your lawn care needs,
                  you're not just investing in a beautiful looking lawn, you're
                  also buying your free time back! Imagine coming home to a
                  perfectly manicured lawn and being able to enjoy summer
                  evenings doing as you please. Services may include mowing,
                  bagging grass, fertilization, trimming shrubs/bushes, edging
                  walks/drives and all services include blowing off all excess
                  grass from walks/drives/etc.
                </p>
              </div>
              <div>
                <div className="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg">
                <StaticImage 
                  src="../images/qclc/qclc-grass.jpeg" 
                  alt="Quality Cuts Lawncare cut grass" 
                  layout="fullWidth"
                  placeholder="blurred"
                  height={200}
                  width={200}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mulch Left */}
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
              <div>
                <div className="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg">
                <StaticImage 
                  src="../images/qclc/qclc-stones.jpeg" 
                  alt="Quality Cuts Lawncare Stones" 
                  layout="fullWidth"
                  placeholder="blurred"
                  height={200}
                  width={200}
                  />
                </div>
              </div>

              <div className="md:pt-8">
                <p className="text-green-500 font-bold text-center md:text-left">
                  Mulch and Stone Delivery
                </p>

                <h1 className="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">
                  Add a fresh look to your landscaping.
                </h1>

                <p className="text-gray-500 sm:text-lg mb-6 md:mb-8">
                  Let us shovel it for you! Nothing helps add curb appeal to
                  your property then freshening up of mulch or decorative rocks
                  which are delivered and installed for your convenience.
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Lawn Mowing Right  */}
        <div className="bg-white py-6 sm:py-8 lg:py-12">
          <div className="max-w-screen-xl px-4 md:px-8 mx-auto">
            <div className="grid md:grid-cols-2 gap-8 lg:gap-12">
              <div className="md:pt-8">
                <p className="text-green-500 font-bold text-center md:text-left">
                  Spring and Fall Cleanup
                </p>

                <h1 className="text-gray-800 text-2xl sm:text-3xl font-bold text-center md:text-left mb-4 md:mb-6">
                  Add a fresh look to your landscaping.
                </h1>

                <p className="text-gray-500 sm:text-lg mb-6 md:mb-8">
                  Let us shovel it for you! Nothing helps add curb appeal to
                  your property then freshening up of mulch or decorative rocks
                  which are delivered and installed for your convenience
                </p>
              </div>
              <div>
                <div className="h-64 md:h-auto bg-gray-100 overflow-hidden rounded-lg shadow-lg">
                <StaticImage 
                  src="../images/gallery/qclc1.jpg" 
                  alt="Quality Cuts Lawncare before and after leaf cleanup" 
                  layout="fullWidth"
                  placeholder="blurred"
                  height={200}
                  width={200}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Testimonials */}
        <section className="text-gray-600 body-font">
          <div className="container px-5 py-24 mx-auto">
            <h1 className="text-3xl font-medium title-font text-gray-900 mb-12 text-center">
              Testimonials
            </h1>
            <div className="flex flex-wrap -m-4">
              <div className="p-4 md:w-1/2 w-full">
                <div className="h-full bg-gray-100 p-8 rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="block w-5 h-5 text-gray-400 mb-4"
                    viewBox="0 0 975.036 975.036"
                  >
                    <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                  </svg>
                  <p className="leading-relaxed mb-6">
                    Hunter and his assistant are amazing. Their attention to
                    detail is top-notch. We are more than pleased with their
                    work and plan to use Quality Cuts going forward. Hunter is
                    very friendly and dependable, and communication before,
                    during and after is excellent.
                  </p>
                  <a className="inline-flex items-center no-underline">
                    <span className="flex-grow flex flex-col pl-4">
                      <span className="title-font font-medium text-gray-900">
                        Nancy
                      </span>
                      <span className="text-gray-500 text-sm">Customer</span>
                    </span>
                  </a>
                </div>
              </div>
              <div className="p-4 md:w-1/2 w-full">
                <div className="h-full bg-gray-100 p-8 rounded">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    className="block w-5 h-5 text-gray-400 mb-4"
                    viewBox="0 0 975.036 975.036"
                  >
                    <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                  </svg>
                  <p className="leading-relaxed mb-6">
                    Hunter is impressive! He is honest, hardworking and
                    dependable. He did a fantastic job on mulch and trimming
                    shrubs at our home. He shows up on time, and takes pride in
                    his work! When he finishes, everything is perfect!
                  </p>
                  <a className="inline-flex items-center no-underline">
                    <span className="flex-grow flex flex-col pl-4">
                      <span className="title-font font-medium text-gray-900">
                        Teri
                      </span>
                      <span className="text-gray-500 text-sm">Customer</span>
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

    </div>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql `
      query {
        slideShow: allFile(
          filter: {relativeDirectory: {eq: "qclc"}}
          sort: {fields: base, order: ASC}
        ) {
          edges {
            node {
              id
              base
              childImageSharp {
                gatsbyImageData(
                  height: 1080
                  width: 1920
                  placeholder: BLURRED
                  quality: 70
                  blurredOptions: {width: 100}
                  transformOptions: {cropFocus: CENTER, fit: COVER}
                )
              }
            }
          }
        }
      }
`